import revive_payload_client_4sVQNw7RlN from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_EnN2NuZxA5 from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/strapi/dist/runtime/plugins/nuxt.js";
import nuxt_1KJf5JSTTR from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/responsible-gambling/dist/runtime/plugins/nuxt.js";
import plugin_hBLWEPkA3b from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import nuxt_bwJGShPQXY from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/plugins/nuxt.js";
import gamehub_client_xuHuWEGZg8 from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/game/dist/runtime/plugins/gamehub.client.js";
import nuxt_ocjF2UNFXX from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/game/dist/runtime/plugins/nuxt.js";
import reactHandles_client_yzuFd1AY5q from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/plugins/reactHandles.client.js";
import uuid_ZvYU0MtAy5 from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/plugins/uuid.js";
import trackingService_client_TBuxj9Xuj7 from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/plugins/trackingService.client.js";
import xmpp_client_Jmo1hCTd03 from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/plugins/xmpp.client.js";
import gtm_I8yKrfnHce from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/plugins/gtm.js";
import auth_client_wxea8rHg3h from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/plugins/auth.client.js";
import apm_client_RWjW0T8hjl from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/plugins/apm.client.js";
import dev_sNfn8VlkVq from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/plugins/dev.js";
import plugin_Jozdw60ZsE from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_ghbUAjaD3n from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_EnN2NuZxA5,
  nuxt_1KJf5JSTTR,
  plugin_hBLWEPkA3b,
  nuxt_bwJGShPQXY,
  gamehub_client_xuHuWEGZg8,
  nuxt_ocjF2UNFXX,
  reactHandles_client_yzuFd1AY5q,
  uuid_ZvYU0MtAy5,
  trackingService_client_TBuxj9Xuj7,
  xmpp_client_Jmo1hCTd03,
  gtm_I8yKrfnHce,
  auth_client_wxea8rHg3h,
  apm_client_RWjW0T8hjl,
  dev_sNfn8VlkVq,
  plugin_Jozdw60ZsE,
  plugin_ghbUAjaD3n,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i
]